import React from "react";
import "../../assets/scss/card.scss";
import Course1 from "../../assets/image/courses/course1.jpeg";
import Course2 from "../../assets/image/courses/course2.JPG";
import Course3 from "../../assets/image/courses/course3.jpeg";
import Course4 from "../../assets/image/courses/course4.jpeg";
import design from "../../assets/image/book/design.avif";
import designLog from "../../assets/image/book/designLog.avif";
import Colors from "../../assets/image/courses/Colors.jpeg";
import moda from "../../assets/image/courses/moda.jpg";
const Courses = () => {
  return (
    <div>
      <div
        role="main"
        className="flex flex-col items-center justify-center mt-32 xs:mt-24 md:mt-32 lg:mt-48"
      >
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-9 text-center text-black italic">
          Kurslarımız
        </h1>
        <p className="text-sm sm:text-md md:text-lg font-semibold leading-normal text-center text-black mt-4 lg:w-1/2 md:w-10/12 w-11/12">
          Ətraflı düyməsinə basaraq daha geniş məlumat əldə edə bilərsiniz.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-10 lg:gap-12 my-12 sm:my-16 md:my-16 lg:my-16 container mx-auto px-4">
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Course3}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-lg sm:text-xl md:text-2xl m-0">Kursumuz</h4>
            <span className="text-xs sm:text-sm font-medium"></span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-64 sm:w-72 md:w-80 lg:w-72 h-20 sm:h-24 md:h-24 lg:h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Course2}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-2xl m-0">
              {" "}
              <h4 className="text-2xl m-0">Kursumuz</h4>
            </h4>
            <span className="text-sm font-medium"></span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-72 h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Course1}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-2xl m-0">
              {" "}
              <h4 className="text-2xl m-0">Kursumuz</h4>
            </h4>
            <span className="text-sm font-medium"></span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-72 h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Course4}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-2xl m-0">Kursumuz</h4>
            <span className="text-sm font-medium"></span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-72 h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
      </div>

      <section class="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24">
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-8xl">
          <div class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
            <img src={designLog} class="p-2 pl-6 pr-5" />
          </div>

          <div class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              PROQRAMLAR
            </h2>

            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
              Build an atmosphere that creates productivity in your organization
              and your company culture.
            </p>
            <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-extrabold">✓</span>
                </span>{" "}
                1-Moda Dizaynı və Texnologiyası
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                2-Moda Eskizi və Dizaynı
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                3-Rəqəmsal Moda Eskizi
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                4-Konstruktor Tikiş Texnoloqu
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                5-Konstruktor Təkmilləşmə
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                6-Klassik Geyimlər
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                7-Uşaq Geyimləri
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                8-İç Geyimlər
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                9-Korsetlər
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                10-Libaslar
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-black border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-[#ffe8c2] rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                11-“Street-Style”
              </li>
            </ul>
          </div>
        </div>
        <div
          class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-8xl lg:px-16"
          style={{ marginTop: "6rem" }}
        >
          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={design} class="pl-4 sm:pr-10" />
          </div>
          <div class="box-border w-full text-black border-solid md:w-1/2 md:pl-6mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Moda Dizaynı və Texnologiyası
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Eskizi istehsal vəziyyətinə gətirə bilirsənsə sən artıq moda
              dizaynerisən! AFL-də dizayner olacaq tələbələr bu proqramda
              kolleksiyaların konstruksiyasını, modelləməsini və tikiş
              texnologiyasını öyrənirlər.Eynilə Kolleksiyaların
              tərkibi,inkişafı,idarə olunması və işin kommersiya tərəfi müzakirə
              olunur.Bu proqramdakı əsas məqsəd texniki eskizə əsaslanaraq
              kolleksiya yaratmaqdır.Onun axıcı və real olması üçün rənq
              spektrlarından, parçalardan, ornament, yaradıcı mulajdan istifadə
              olunur.Eyni zamanda kolleksiyanın rəqəmsal veriyasını yaratmaq
              üçün “Adobe” proqramları tədris olunur.Əlavə olaraq hər dizaynerin
              bilməli olduğu məqamlar, moda tarixi, texniki və yaradıcı eskiz,
              stillər, rəng nəzəriyyəsi, parçalar, printlər, kollajlar və
              “moodboard”da proqrama daxildir. Tələbə sonda sağlam “portfolio”
              əldə edir.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-8xl lg:px-16">
          <div class="box-border w-full text-black border-solid md:w-1/2 md:pl-6 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Moda Eskizi və Dizaynı
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Bu proqramda tələbələr moda tarixi, trendlər, rənglər, stillər,
              parçalar, texniki və yaradıcı eskiz ilə yanaşı geyim dizaynerinə
              vacib olan bir çox önəmli məlumatlar əldə edirlər. "Texniki eskiz"
              - Dizayner və istehsalçı arasında vizual əlaqədir.Geyimi düzgün
              ölçü və proporsiyaları ilə əks etdirən dəqiq eskiz növüdür.Bu
              eskiz növü bütün istehsal mərhələsində və kolleksiyanın
              yaradılmasında geniş istifadə olunur.Yaradıcı eskizi əvəz edir,
              daha professional sayılır.Ön sınamanı ("primerka")- nı
              asanlaşdırır.Biçimin düzgün qurulmasına kömək edir və modelləməyə
              istiqamətdir.Texniki rəsm üzərindən mürəkkəb biçim lekalların
              masştabla kiçilmiş versiyasını almaq mümkün olur.Lazımı
              düzəlişləri hələ geyim hazır olmadan birbaşa eskizdə etmək sizi
              vaxt itkisindən və əlavə zəhmətdən azad edir. "Yaradıcı eskiz" -
              Geyimin və ya kolleksiyanın xırda detalları, parça fakturası, rəng
              uyumu, stili və s. ilə model üzərindəki rəngli təsviridir.Fərqli
              bədən pozaları və dəri rənglənməsi də proqramda tədris olunur.“For
              eskiz" - Kobud, gözlənilmədən, ani olan, geyim modelinin
              təsviridir. Bu eskiz tipi yalnız dizaynerin başa düşəcəyi
              eskizdir. İdeya gəldiyi zaman tez tələsik onu özümüz üçün təsvir
              edirik. Bu növ eskizdə dizayner öz potensial və xəyal gücünü
              çərçivəsiz olaraq istifadə edir. Bir növ buna cızma qara da deyə
              bilərik.
            </p>
          </div>

          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={moda} class="pl-4 sm:pr-10 xl:pl-10 lg:pr-32" />
          </div>
        </div>
      </section>

      {/* <section>
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-8xl lg:px-16">
          <div class="box-border w-full text-black border-solid md:w-1/2 md:pl-6 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Rəqəmsal Moda Eskizi
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Bu proqramda tələbələr öz kolleksiyalarını “Adobe” proqramlarından
              istifadə edərək rəqəmsal veraiyada yaradırlar. “Aobe Photoshop”-
              proqramla ətraflı tanış olduqdan sonra düz və hərəkət edən skletin
              quruluşu, geyimlərin çəkilməsi, rəng və parçaların geyim üzərində
              təsviri, qəliz geyim obrazları və yaradıcı eskizi öyrənirlər.
              “Adobe İllustrator”- proqramla ətraflı tanış olduqdan sonra “pen
              tull” ilə fiqurların çalışması, texniki eskiz,”pattern”
              düzəldilməsi, geyim kollajı və onların geyimə çevirilməsini
              öyrənirlər.
            </p>
          </div>

          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={design} class="pl-4 sm:pr-10" />
          </div>
        </div>
      </section> */}

      {/* <section>
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">
          <div class="box-border w-full text-black border-solid md:w-1/2 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Konstruktor Tikiş Texnoloqu(Dərzilik)
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Modada dizaynerlər qədər önəmlidir konstruktorlar! Onlar qəlib
              çıxarmaqdan modelin formalaşmasına qədər bütün mərhələlərindən
              məsuldurlar. Buna görə də geyim modelinin eskizə dönməsindən
              dərhal sonra konstruktorun işi başlayır.Tekstil sektorunda işçilər
              adətən usta yanında köməkçi olaraq yetişirlər. Ancaq bu
              konstruktor üçün keçərli deyil. Çünki konstruktor olmaq bir sıra
              texniki bilgilərdən keçir. Öncə bu sahənin geniş olaraq təhsilini
              almaq lazımdır.Konstruktor bir tekstil məhsulunu seriya
              istehsalına keçməsi üçün onu uyğun ölçülərdə hazırlayar və
              qəlibini çıxarar. İstifadə ediləcək xammalı təsbit edərək nümunə
              (prototip) tikişini həyata keçirər.O, geyim modelində baş verəcək
              qüsurları aradan qaldıraraq geyimi istehsal üçün ideal versiyaya
              gətirər. Geyim modellərinin seriya istehsalına keçə bilməsi üçün
              bir çox fərqli hesablamalardan keçərək nəticəyə çatar.İdeal
              versiyada hazırlanmış qəlibin qüsursuz tikilməsi konstruktorun
              öhdəliyində deyil.Bu işi bir çox tikiş yollarını bilən tikiş
              texnoloqu həyata keçirir.Onlar müxtəlif parça və modellərdə
              istifadə oluacaq tikiş texnologiyalarını proses əsasında təyin
              edirlər və geyimin ideal formada tikişini həyata keçirirlər.
            </p>
          </div>

          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={design} class="pl-4 sm:pr-10" />
          </div>
        </div>
      </section> */}
      {/* <section>
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">
          <div class="box-border w-full text-black border-solid md:w-1/2 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Konstruktor Təkmilləşmə(Biçici)
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Modada dizaynerlər qədər önəmlidir konstruktorlar! Onlar qəlib
              çıxarmaqdan modelin formalaşmasına qədər bütün mərhələlərindən
              məsuldurlar. Buna görə də geyim modelinin eskizə dönməsindən
              dərhal sonra konstruktorun işi başlayır.Tekstil sektorunda işçilər
              adətən usta yanında köməkçi olaraq yetişirlər. Ancaq bu
              konstruktor üçün keçərli deyil. Çünki konstruktor olmaq bir sıra
              texniki bilgilərdən keçir. Öncə bu sahənin geniş olaraq təhsilini
              almaq lazımdır.Konstruktor bir tekstil məhsulunu seriya
              istehsalına keçməsi üçün onu uyğun ölçülərdə hazırlayar və
              qəlibini çıxarar. İstifadə ediləcək xammalı təsbit edərək nümunə
              (prototip) tikişini həyata keçirər.O, geyim modelində baş verəcək
              qüsurları aradan qaldıraraq geyimi istehsal üçün ideal versiyaya
              gətirər. Geyim modellərinin seriya istehsalına keçə bilməsi üçün
              bir çox fərqli hesablamalardan keçərək nəticəyə çatar.
            </p>
          </div>

          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={design} class="pl-4 sm:pr-10" />
          </div>
        </div>
      </section> */}

      <section className="my-20">
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-8xl lg:px-16">
          <div class="box-border w-full text-black border-solid md:w-1/2 md:pl-6 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Qısa müddətli Proqramlar
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Bu formada, yəni detallara bölərək təqdim olunan təhsil maraqlı və
              faydalıdır. Dünyada moda təhsili təcrübəsində tələbələrə geniş
              imkan yaradılır, istənilən proqramı seçim etmək şansı verilir.
              Azerbaijan Fashion LAB da ölkədəki moda sevərlərə bu imkanları
              yaradır.Təcrübəmizdən deyə bilərik ki, gələn bir çox tələbə fərqli
              istiqamətdə öyrənmək iş qurmaq və irəliləmək istəyir. Belə olduqda
              şablon proqramın yetərli olması təbii ki mümkün deyil. Buna görə
              də istəyirsiniz “Klassik Geyimlər”, istəyirsiniz öz dizaynınız
              olan kolleksiyanız və ya baza geyimlərin ön sınamasız, standarta
              uyğun hazırlanması, istəyirsiniz “Uşaq Geyimləri”, ”Korsetlər”,
              ”Libaslar”, ”İç Geyimlər”, ”Street style”(kişi və qadınların
              geyinə biləcəyi geyimlər)eyni zamanda da bütün proqramın
              öyrədilməsini seçim edin. Bu proqramlarda tələbələr sadalanan
              geyim növlərinin həm konstruksiyasını həm də tikiş texnologiyasını
              peşəkar şəkildə öyrənirlər.
            </p>
          </div>

          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={Colors} class="pl-4 sm:pr-10 xl:pl-10 lg:pr-32" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Courses;
